@mixin override-mat-tab-link {
  .booking-list {
    .mat-tab-header {
      border-bottom: none !important;

      .mat-tab-labels {
        width: max-content;
        @apply justify-between gap-8
      }

      .mat-tab-label {
        font-size: 18px;
        font-weight: 600;
        padding:0 !important;
        min-width: 0 !important;

        &.mat-tab-label-active {
          opacity: 100%;
          color: #b637cc;
        }
      }
    }
  }
}

@mixin override-mat-checkbox {
  .custom-checkbox {
    .mat-checkbox-inner-container {
      width: 22px !important;
      height: 22px !important;
    }

    .mat-checkbox-frame {
      border: #b637cc solid 3px !important;
      border-radius: 4px !important;
    }

    .mat-checkbox-background {
      border: #b637cc solid 3px !important;
      border-radius: 3.5px !important;
      background: white !important;
    }
  }

  mat-checkbox.custom-checkbox .mat-checkbox-checkmark-path {
    stroke: #b637cc !important;
    stroke-width: 4px !important;
  }
}

@mixin override-mat-menu {
  .notificationMenu+* .cdk-overlay-pane {
    background-color: #f8f8f8;
    color: #000;
    max-height: 426px;
    border-radius: 16px;
    box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.6);
    border: solid 1px #d2d2d2;
  }

  .notificationMenu+* .mat-menu-panel {
    max-width: 400px;
    border-radius: 16px;
    position: relative;
  }

  .notification-menu-item.mat-menu-item {
    line-height: 18px;
    height: auto !important;
    padding: 12px 24px;
    text-align: left;
    white-space: normal;
  }
}
